<template>
    <div class="box">
        <v-timeline>
            <v-timeline-item v-for="(ns, i) in news" :key="i" :color="ns.color" small>
                <template v-slot:opposite>
                    <span
                        :class="`headline font-weight-bold ${ns.color}--text`"
                        v-text="ns.ns"></span>
                </template>
                <div class="py-4">
                    <h2 :class="`headline font-weight-light mb-4 ${ns.color}--text`">
                        {{ ns.date }}
                    </h2>
                    <div>
                        <a :href="ns.link">{{ ns.title }}</a>
                    </div>
                </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
    export default {
        name: 'NewsBullying',
        data() {
            return {
                news: [
                    {
                        date: '23 maio. 2022',
                        title: 'Impacto do bullying é grande tanto para vítima quanto para o autor, diz pesquisadora',
                        link: 'https://www.cnnbrasil.com.br/nacional/impacto-do-bullying-e-grande-tanto-para-vitima-quanto-para-o-autor-diz-pesquisadora/',
                        color: 'black'
                    },
                    {
                        date: '20 out. 2021',
                        title: 'Brasil precisa avançar em leis e políticas públicas contra bullying, diz especialista',
                        link: 'https://www.cnnbrasil.com.br/saude/brasil-precisa-avancar-em-leis-e-politicas-publicas-contra-bullying-diz-especialista/',
                        color: 'black'
                    },
                    {
                        date: '29 set. 2021',
                        title: 'Na direção do outro ou como tornar a escola um ambiente de carinho',
                        link: 'https://exame.com/bussola/na-direcao-do-outro-ou-como-tornar-a-escola-um-ambiente-de-carinho/',
                        color: 'black'
                    },
                    {
                        date: '06 out. 2020',
                        title: 'Criança se esconde em banheiro de escola por três dias após sofrer bullying',
                        link: 'https://noticias.uol.com.br/internacional/ultimas-noticias/2020/10/06/crianca-vitima-de-bullying-se-esconde-em-banheiro-de-escola-por-tres-dias.htm',
                        color: 'black'
                    },
                    {
                        date: '22 fev. 2020',
                        title: 'A vida após o bullying: como é a luta para superar a violência sofrida na infância e na adolescência',
                        link: 'https://gauchazh.clicrbs.com.br/pioneiro/cultura-e-lazer/noticia/2020/02/a-vida-apos-o-bullying-como-e-a-luta-para-superar-a-violencia-sofrida-na-infancia-e-na-adolescencia-12189482.html',
                        color: 'black'
                    },
                    {
                        date: '08 out. 2018',
                        title: 'Campanha cartoonizada da ONU mostra a realidade do bullying',
                        link: 'https://exame.com/marketing/campanha-cartoonizada-da-onu-mostra-a-realidade-do-bullying/',
                        color: 'black'
                    }
                ]
            };
        }
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .news h1 {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .news ul li {
        margin-top: 30px;
        text-align: justify;
    }

    .news ul li a:link {
        color: #0142c4;
        font-weight: bold;
        text-decoration: none;
        font-size: 20px;
    }

    .news ul li a:hover {
        color: #0142c4;
        text-decoration: none;
    }

    .news ul li a:visited {
        color: #0142c4;
        text-decoration: none;
    }
</style>
